<template>
  <div class="d-flex flex-column overflow-y-auto">
    <v-dialog
      v-model="iframeDialog"
      max-width="1200"
      @click:outside="
        iframeUrl = null;
        iframeDialog = false;
      "
    >
      <div class="ant-glass-background overflow-hidden" style="width: 1200px">
        <iframe :src="iframeUrl" height="600" width="100%" />
      </div>
    </v-dialog>
    <div class="d-flex align-center">
      <v-spacer />
      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn icon v-on="on" @click="openChapter()">
            <v-icon> mdi-information-outline </v-icon>
          </v-btn>
        </template>
        <span>Ga naar BBL online</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            icon
            v-on="on"
            @click="toggleProjectRisks = !toggleProjectRisks"
          >
            <v-icon :color="toggleProjectRisks ? 'primary' : ''">{{
              toggleProjectRisks
                ? 'mdi-image-filter-center-focus-strong'
                : 'mdi-image-filter-center-focus-strong-outline'
            }}</v-icon>
          </v-btn>
        </template>
        <span>{{
          toggleProjectRisks
            ? "Laat alle risico's zien"
            : "Laat alleen project risico's zien"
        }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon v-on="on" @click="requestReport">
            <v-icon>mdi-export</v-icon>
          </v-btn>
        </template>
        <span>Download keuringsplan rapport</span>
      </v-tooltip>
    </div>
    <div
      class="overflow-y-auto"
      style="height: calc(100vh - 46px - 46px - 36px)"
    >
      <div
        v-for="headChapterItem in headChapters"
        :key="headChapterItem.id"
        class="px-1 py-1"
      >
        <v-subheader
          style="height: auto; font-size: 16px"
          class="analysis-chapter"
        >
          Hoofdstuk {{ headChapterItem.chapter }}.
          {{ headChapterItem.title }}
        </v-subheader>
        <div class="pl-3 py-2">
          <div
            v-for="subChapterItem in getSubChapters(headChapterItem)"
            :key="subChapterItem.id"
          >
            <v-subheader
              style="height: auto"
              class="analysis-chapter align-center d-flex pl-1"
            >
              <v-tooltip right>
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    class="mr-1"
                    :color="
                      getSubChapterStateColor(
                        getSubChapterState(
                          subChapterItem.chapter,
                          subChapterItem.sub_chapter
                        )
                      )
                    "
                    v-on="on"
                  >
                    {{
                      getSubChapterState(
                        subChapterItem.chapter,
                        subChapterItem.sub_chapter
                      )
                        ? 'mdi-menu-right'
                        : 'mdi-menu-right-outline'
                    }}
                  </v-icon>
                </template>
                <span>{{
                  getSubChapterState(
                    subChapterItem.chapter,
                    subChapterItem.sub_chapter
                  )
                    ? getSubChapterState(
                        subChapterItem.chapter,
                        subChapterItem.sub_chapter
                      )
                    : 'Status unknown'
                }}</span>
              </v-tooltip>
              <div>
                {{ subChapterItem.chapter }}.{{ subChapterItem.sub_chapter }}:
                {{ subChapterItem.title }}
              </div>
            </v-subheader>
            <div class="py-1 pl-3">
              <div
                v-for="risk in getRisksByChapter(subChapterItem)"
                :key="risk.id"
                style="font-size: 12px"
                class="d-flex align-center py-1 px-2 analysis-risk-item"
                :class="{
                  'analysis-risk-item-active':
                    risk === wkbInspectionPlanRiskDetail,
                }"
                @click="clickedRisk(risk)"
              >
                <v-icon
                  :color="isRiskInProject(risk) ? getRiskColor(risk) : ''"
                  small
                  class="mr-2"
                  @click.stop="toggleRisk(risk)"
                  >{{
                    isRiskInProject(risk) ? 'mdi-circle' : 'mdi-circle-outline'
                  }}</v-icon
                >
                <div>
                  {{ risk.friendly_id }}
                </div>
                <v-divider class="mx-2" vertical />
                <div class="mr-2" style="min-width: 40px">
                  {{ risk.chapter }}.{{ risk.sub_chapter }}.{{
                    risk.number ? risk.number : '-'
                  }}
                </div>
                <div>
                  {{ risk.name }}
                </div>
                <v-spacer />
                <div class="d-flex justify-end">
                  <v-tooltip left>
                    <template #activator="{ on, attrs }">
                      <v-icon small class="mr-2" v-on="on"
                        >mdi-format-list-numbered</v-icon
                      >
                      <div>
                        {{ countRiskMeasures(risk) }}
                      </div>
                    </template>
                    <span>Active measurements</span>
                  </v-tooltip>

                  <v-divider class="mx-2" vertical />
                  <div class="font-italic" style="min-width: 50px">
                    {{ risk.NLSFB ? risk.NLSFB : '-' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-divider />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { executeCustomModuleCall } from '@/services/api/module.api';
import { DHME_WKB_INSPECTION_PLAN } from '@/modules/modules';

export default {
  name: 'DhmeWkbInspectionPlanRisks',
  data: () => {
    return {
      toggleProjectRisks: false,
      iframeUrl: null,
      iframeDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'wkbInspectionPlanRisks',
      'wkbInspectionPlanProjectRisks',
      'wkbInspectionPlanRiskDetail',
      'wkbInspectionPlanProjectRisksMeasures',
      'wkbInspectionPlanChapters',
      'wkbInspectionPlanRiskMapping',
      'wkbRiskIPStatuses',
    ]),

    headChapters() {
      let chapters = this.wkbInspectionPlanChapters
        .filter((item) => !item.sub_chapter)
        .sort((a, b) => a.chapter - b.chapter);

      if (this.toggleProjectRisks) {
        if (this.wkbInspectionPlanProjectRisks.length === 0) {
          return [];
        }
        return chapters.filter((chapter) => {
          let risks = this.wkbInspectionPlanRisks.filter(
            (risk) => chapter.chapter === risk.chapter
          );
          let projectRisks = risks.filter((risk) => this.isRiskInProject(risk));
          return projectRisks.length > 0;
        });
      }

      return chapters;
    },
  },
  mounted() {
    this.toggleProjectRisks = this.wkbInspectionPlanProjectRisks.length > 0;
  },
  methods: {
    async requestReport() {
      const moduleId = this.project.modules.find(
        (module) => module.route === DHME_WKB_INSPECTION_PLAN
      ).id;
      await executeCustomModuleCall(
        this.project.id,
        moduleId,
        'exportInspectionPlan'
      );

      this.$store.commit('showNotification', {
        content:
          'Export requested. You will be notified when the download is ready',
        color: 'info',
      });
    },
    getSubChapterState(chapter, subchapter) {
      return this.wkbRiskIPStatuses.find(
        (record) => record.chapter === `${chapter}.${subchapter}`
      )?.status;
    },
    getSubChapterStateColor(type) {
      switch (type) {
        case 'afgehandeld':
          return '#66bb6a';
        case 'gering':
          return '#d8d827';
        case 'mogelijk':
          return '#ffc107';
        case 'risico':
          return '#ef5350';
        case 'n.v.t.':
          return '#424242';
        default:
          return '#7d7d7d';
      }
    },
    openChapter() {
      this.iframeUrl = `https://www.bblonline.nl/`;
      this.iframeDialog = true;
    },
    countRiskMeasures(risk) {
      return this.wkbInspectionPlanProjectRisksMeasures.filter(
        (item) => item.risk_id === risk.id
      ).length;
    },
    isRiskInProject(risk) {
      return (
        this.wkbInspectionPlanProjectRisks.findIndex(
          (item) => item.risk_id === risk.id
        ) !== -1
      );
    },
    getSubChapters(headChapter) {
      let subChapters = this.wkbInspectionPlanChapters
        .filter(
          (item) => item.chapter === headChapter.chapter && item.sub_chapter
        )
        .sort((a, b) => a.sub_chapter - b.sub_chapter);

      if (this.toggleProjectRisks) {
        return subChapters.filter((item) => {
          let risks = this.wkbInspectionPlanRisks.filter(
            (risk) =>
              item.chapter === risk.chapter &&
              item.sub_chapter === risk.sub_chapter
          );
          let projectRisks = risks.filter((risk) => this.isRiskInProject(risk));
          return projectRisks.length > 0;
        });
      }

      return subChapters;
    },
    getRiskColor(risk) {
      let projectRisk = this.wkbInspectionPlanProjectRisks.find(
        (item) => item.risk_id === risk.id
      );
      switch (projectRisk.risk_lvl) {
        case 0:
          return '#66bb6a';
        case 1:
          return '#d8d827';
        case 2:
          return '#ffc107';
        case 3:
          return '#ef5350';
        case 4:
          return '#424242';
        default:
          return '#424242';
      }
    },
    getRisksByChapter(chapter) {
      let risks = this.wkbInspectionPlanRisks
        .filter(
          (risk) =>
            risk.chapter === chapter.chapter &&
            risk.sub_chapter === chapter.sub_chapter
        )
        .sort((a, b) => a.number - b.number);

      if (this.toggleProjectRisks) {
        return risks.filter((risk) => this.isRiskInProject(risk));
      }
      return risks;
    },
    clickedRisk(risk) {
      if (this.isRiskInProject(risk)) {
        this.$store.commit('module_wkb_inspection_plan_select_risk', risk);
      } else {
        this.$store.commit('showNotification', {
          content: 'Please add risk to project first by selecting the checkbox',
          color: 'info',
        });
      }
    },
    toggleRisk(risk) {
      let bool = !this.isRiskInProject(risk);
      if (bool) {
        // add risk to project
        let body = {
          record: {
            risk_id: risk.id,
            reason: null,
            risk_lvl: 3,
            comments: null,
          },
        };
        this.$store.dispatch('addProjectRisk', body);
      } else {
        // remove risk from project
        let projectRisk = this.wkbInspectionPlanProjectRisks.find(
          (item) => item.risk_id === risk.id
        );
        this.$store.commit('module_wkb_inspection_plan_select_risk', null);

        this.$store.dispatch('deleteProjectRisk', projectRisk.id);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.analysis-risk-item {
  cursor: pointer;
  transition: 100ms ease;

  &.analysis-risk-item-active {
    background: #f2f2f2;
  }
  &:hover {
    background: #f2f2f2;
  }
}

.analysis-chapter {
  .extra-info-icon {
    opacity: 0;
    pointer-events: none;
    transition: 200ms ease-out;
  }

  &:hover {
    .extra-info-icon {
      opacity: 1;
      pointer-events: all;
    }
  }
}
</style>
